#CSI-header-container {
    background-image: url('../../../Assets/Ilkommunity/CSI/bg-csi.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    height: 640px;
    margin-top: .8rem;
    justify-content: center;
    border-top: 4px solid black;
    border-bottom: 4px solid black;
}

.CSI-header-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 15.81rem;
}

.CSI-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 377px;
    height: 208px;
}

.CSI-header h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
}

.CSI-header p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

#CSI-main-container {
    background-image: url('./../../../Assets/Background/pattern-top.svg'), url('./../../../Assets/Background/pattern-bottom.svg');
    background-size: contain;
    background-position: top right, bottom left;
    background-repeat: no-repeat;
    padding: 80px 0 0 0;
}

.CSI-content-container{
    overflow: hidden;
}

.CSI-content-deskripsi-tujuan {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 80px;
}

.CSI-content-deskripsi-tujuan #CSI-deskripsi {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #6B7280;
    cursor: pointer;
    text-align: center;
}

#CSI-deskripsi.CSI-active {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    border-bottom: 4px solid black;
    cursor: pointer;
    padding-bottom: 4px;
}

#CSI-tujuan {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #6B7280;
    cursor: pointer;
    text-align: center;
}

#CSI-tujuan.CSI-active {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    border-bottom: 4px solid black;
    cursor: pointer;
    padding-bottom: 4px;
}

.CSI-content-deskripsi-tujuan {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #6B7280;
    cursor: pointer;
    text-align: center;
}

.CSI-content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.CSI-deskripsi-content,
.CSI-tujuan-content {
    max-width: 1057px;
    width: 90%;
    height: auto;
}

.CSI-deskripsi-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E9AD33;
    border: 4px solid #16052B;
    box-shadow: 7px 7px 0px #000000;
    margin: 32px 0 0 0;
    padding: 40px 40px 40px 44px;
    margin-left: auto;
    margin-right: auto;
}


.CSI-deskripsi-content p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
}

.CSI-tujuan-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #E9AD33;
    border: 4px solid #16052B;
    box-shadow: 7px 7px 0px #000000;
    margin: 32px 32px 0 32px;
    padding: 40px 44px 40px 44px;
    list-style-type: none;
}

.CSI-tujuan-content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
}

.CSI-prestasi-container {
    margin: 80px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.CSI-prestasi-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    max-width: 1060px;
    width: 90%;
    height: 100%;
    border: 3px solid #16052B;
    box-shadow: 3px 4px 0px #000000;
    background-color: #FFFFFF;
    flex-direction: column;
    padding: 45px 45px 45px 45px;
}

.CSI-prestasi-content li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.CSI-prestasi-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 52px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 4px 4px 0px #000000;
    transform: translateY(25px);
}

.CSI-prestasi-header h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.CSI-narahubung-container {
    padding: 80px 0 160px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.CSI-narahubung-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    max-width: 1060px;
    width: 90%;
    height: 174px;
    border: 3px solid #16052B;
    box-shadow: 3px 4px 0px #000000;
    background-color: #FFFFFF;

}

.CSI-narahubung-content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.CSI-narahubung-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 228px;
    height: 52px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 4px 4px 0px #000000;
    transform: translateY(25px);
}

.CSI-narahubung-header h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

@media(max-width: 1024px) {

    .CSI-header {
        width: auto;
        height: auto;
    }

    .CSI-header-wrapper {
        column-gap: normal;
    }

    .CSI-header p {
        font-size: large;
        width: 401px;
    }

    .CSI-deskripsi-content {
        width: 90%;
        height: 320px;
    }

    .CSI-tujuan-content {
        width: 90%;
        height: 152px;
    }

    .CSI-prestasi-content {
        width: 90%;
        height: 286px;
    }

    .CSI-narahubung-content {
        width: 90%;
        height: 190px;
    }
}

@media (min-width: 769px) and (max-width: 1023px){
    #CSI-header-container {
        width: 100%;
        max-width: 1024px;
        height: 500px;
    }
}

@media(max-width: 768px) {
    #CSI-header-container {
        background-image: url("../../../Assets/Ilkommunity/CSI/CSI-Header-Background-Tab.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        justify-content: center;
        height: 522px;
        border-top: 4px solid #111827;
        border-bottom: 4px solid #111827;
    }


    .CSI-header-wrapper {
        width: 100%;
        max-width: 670px;
    }

    .CSI-header {
        width: 100%;
        max-width: 377px;
    }

    .CSI-header p {
        font-size: 16px;
        width: auto;
    }

    .CSI-deskripsi-content {
        width: 90%;
        max-width: 668px;
        height: auto;
    }

    .CSI-tujuan-content {
        width: 90%;
        max-width: 668px;
        height: auto;
    }

    .CSI-prestasi-content {
        width: 90%;
        max-width: 668px;
        height: auto;
    }

    .CSI-narahubung-content {
        width: 90%;
        max-width: 668px;
        height: 190px;
    }
}

@media (min-width: 481px) and (max-width: 767px){
    #CSI-header-container {
        width: 100%;
        max-width: 768px;
        height: 410px;
    }

    .CSI-header-wrapper{
        grid-template-columns: none;
    }

    .CSI-header p{
        width: 80%;
    }
}


@media screen and (max-width: 480px) {
    #CSI-header-container {
        background-image: url("../../../Assets/Ilkommunity/CSI/bg-mobile-csi.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        justify-content: center;
        height: 522px;
        border-top: 4px solid #111827;
        border-bottom: 4px solid #111827;
        align-items: unset;
    }

    #CSI-deskripsi,
    #CSI-tujuan {
        font-size: 22px;
        line-height: 28px;
        overflow-x: hidden;
    }


    .CSI-header-wrapper {
        grid-template-columns: none;
        width: auto;   
        align-items: end;
        padding-bottom: 1rem;
    }

    .CSI-header h2 {
        font-size: 45px;
    }

    .CSI-header p {
        font-size: 14px;
        max-width: 330px;
    }

    .CSI-deskripsi-content {
        width: 90%;
        height: auto;
    }

    .CSI-tujuan-content {
        width: 90%;
        height: auto;
    }

    .CSI-prestasi-content {
        width: 90%;
        height: auto;
    }

    .CSI-prestasi-content li{
        font-size: 16px;
        line-height: 24px;
    }

    .CSI-narahubung-content {
        width: 90%;
    }

    #CSI-deskripsi.CSI-active {
        font-size: 28px;
        text-shadow: 2px 2px 0px #040404;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #040404;
    }
    #CSI-tujuan.CSI-active {
        font-size: 28px;
        text-shadow: 2px 2px 0px #040404;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #040404;
    }
}