li {
    cursor: default;
}

.navbar {
    position: relative;
    font-family: Changa One;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #E49800;
    border-bottom: 4px solid #040404;
}

.navbar-heading {
    display: block;
    margin: 25px 80px 25px 80px;
}

.navbar-burger {
    display: none;
}

.burger {
    display: none;
}

.navbar-cross {
    display: none;
}

.navbar-list {
    margin: 25px 80px 25px 80px;
    display: flex;
    align-items: center;
    gap: 40px;

}

.navbar-list li {
    font-size: 18px;
    list-style: none;
    letter-spacing: 0.1px;
    width: max-content;
    transition: 0.3s;
}

.navbar-list li a {
    text-decoration: none;
    color: black;
    transition: 0.3s;
    letter-spacing: 0.1px;
}

.navbar-list li:hover a {
    font-family: 'Changa One';
    font-weight: 350;
    line-height: 36px;
    color: #F0E4D4;
    text-shadow: 2.5px 1.5px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
}

.navbar-list li:hover {
    font-family: 'Changa One';
    font-weight: 350;
    line-height: 36px;
    color: #F0E4D4;
    text-shadow: 2.5px 1.5px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
}

.sub-menu-1 {
    display: none;
    width: 100%;
    max-width: 330px;
    height: max-content;
}

.profil-dropdown:hover .sub-menu-1 {
    display: block;
    position: absolute;
    background-color: #E49800;
    padding: 20px 0 10px 10px;
    border-bottom: 4px solid #111827;
    z-index: 99;
}


.divisi-dropdown li {
    text-decoration: none;
    color: black;
    transition: 0.3s;
    letter-spacing: 0.1px;
}

.sub-menu-1 li {
    padding: 0 0 22px 28px;
    height: 60px;
}

.sub-menu-2 {
    display: none;
}

.sub-menu-3 {
    display: none;
    width: 100%;
    max-width: 200px;
    height: max-content;
}

.divisi-dropdown:hover .sub-menu-2 {
    display: block;
    position: absolute;
    background-color: #E49800;
    padding: 10px;
    border-bottom: 4px solid #111827;
    width: 100%;
    max-width: 320px;
    height: max-content;
    transform: translate(320px, -60px);
}

.komunitas-dropdown:hover .sub-menu-3 {
    display: block;
    position: absolute;
    background-color: #E49800;
    padding: 20px 0 10px 10px;
    border-bottom: 4px solid #111827;
    z-index: 99;
}

.sub-menu-2 li {
    padding: 0 0 22px 28px;
}

.sub-menu-3 li {
    padding: 0 0 22px 28px;
    height: 60px;
}


.mobile-navbar-container {
    display: none;
}

.background-overlay {
    display: none;
}



@media only screen and (max-width: 1024px) {
    .navbar-list li a {
        font-size: 16px;
    }
}


@media(max-width: 992px) {
    .navbar-heading {
        margin-left: 40px;
    }

    #navcross {
        display: block;
    }

    .navbar-list {
        display: none;
    }

    .burger {
        display: block;
    }

    .navbar-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 40px 0 0;
    }

    .cross-active {
        display: flex;
        justify-content: space-between;
        padding: 25px 40px 25px 40px;
        border-bottom: 4px solid #040404;
    }

    #navcross {
        cursor: pointer;
    }

    .mobile-navbar-container {
        display: block;
        position: absolute;
        top: 3px;
        right: 0;
        background-color: #E49800;
        height: auto;
        border-bottom: 4px solid #040404;
        z-index: 99;
        max-width: 583px;
        width: 70%;
    }

    .mobile-navbar {
        min-height: 600px;
        padding: 35px 2.47rem 0 2.47rem;
    }

    .mobile-navbar li {
        display: flex;
        padding: 0 0 1rem 0;
        list-style: none;
        font-family: Changa One;
        font-weight: 350;
        font-size: 2rem;
        line-height: 2.5rem;
        text-decoration: none;
        color: black;
        transition: 0.3s;
        letter-spacing: 0.1px;
    }

    .mobile-navbar li a {
        font-family: Changa One;
        font-weight: 350;
        font-size: 2rem;
        line-height: 2.5rem;
        text-decoration: none;
        color: black;
        transition: 0.3s;
        letter-spacing: 0.1px;
    }

    .mobile-navbar li a:hover {
        font-size: 2.5rem;
        font-style: italic;
        color: #F0E4D4;
        text-shadow: 2.5px 1.5px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
        transition: 0.3s;
    }

    .mobile-icon-container {
        padding: 0 2.47rem 1.19rem 2.47rem
    }

    .mobile-icon {
        display: flex;
        gap: 2.5rem;
        padding-bottom: 1.5rem;
    }


    .mobile-copyright h2 {
        color: #111827;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 0.00625rem;
    }

    .mobile-navbar-dropdown {
        display: flex;
        flex-direction: column;
    }

    .mobile-navbar-dropdown p {
        display: flex;
        justify-content: space-between;
    }

    .mobile-navbar-dropdown img{
        width: 32px;
    }

    .mobile-navbar .dropdown-divisi p {
        display: flex;

        flex-direction: row;
        width: 100%;
    }

    .mobile-navbar .dropdown-menu .dropdown-divisi a,
    .mobile-navbar .dropdown-menu li a {
        font-family: Changa One;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        align-self: center;
    }

    .dropdown-menusub-divisi {
        display: flex;
        flex-direction: row;
        background-color: pink;
    }

    .mobile-navbar .dropdown-menu {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 4px solid var(--gray-10, #111827);
        padding: 15px 0px 24px 28px;
        align-self: flex-end;
    }

    .mobile-navbar .dropdown-divisi {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 8px;
    }

    .mobile-navbar .dropdown-menu li {
        padding: 0;
    }

    .mobile-navbar .dropdown-menu a {
        font-size: 14px;
        line-height: 20px;
    }

    .mobile-navbar .dropdown-divisi img {
        width: 32px;
    }

    .mobile-navbar .dropdown-menu li a:hover {
        font-size: 2rem;
        color: #F0E4D4;
        text-shadow: 2.5px 1.5px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
        transition: 0.3s;
    }

    .mobile-navbar #dropdown-menu-divisi {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        width: 100%;
        padding: 20px 0px 0 28px;
        gap: 12px;
    }

    .mobile-navbar #dropdown-menu-divisi a {
        font-size: 22px;
        line-height: 28px
    }


    .mobile-navbar .dropdown-menu-komun li {
        margin-bottom: 8px;
    }

    .mobile-navbar .dropdown-menu-komun li a {
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
    }

    .background-overlay.closed {
        display: none;

    }

    .background-overlay.active {
        display: block;
        opacity: 1;
        pointer-events: auto;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 1200px;
        overflow-x: hidden;
        background-color: rgba(0, 0, 0, 0.5);
        pointer-events: none;
        z-index: 20;
        transition: opacity 0.3s ease;
    }

    .dropdown-menu,
    .dropdown-menu-komun,
    .dropdown-menu-divisi {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;
    }

    .dropdown-menu,
    .dropdown-menu-komun.active,
    .dropdown-menu-divisi.active {
        opacity: 1;
        visibility: visible;
        animation: fadeIn 0.5s ease-in-out;
    }


}


@media(max-width: 480px) {
    .mobile-navbar {
        overflow-x: hidden;
        min-height: 450px;
    }

    .mobile-icon {
        gap: 0;
    }

    .mobile-icon a {
        padding-right: clamp(0.625rem, -3.125rem + 18.75vw, 2.5rem);

    }

    .mobile-navbar,
    .mobile-icon-container,
    .cross-active {
        padding-left: clamp(0.625rem, -3.125rem + 18.75vw, 2.5rem);
        padding-right: clamp(0.625rem, -3.125rem + 18.75vw, 2.5rem);
    }

    .mobile-navbar li a {
        font-family: Changa One;
        font-weight: 350;
        font-size: 2rem;
        line-height: 2.5rem;
        text-decoration: none;
        color: black;
        transition: 0.3s;
        letter-spacing: 0.1px;
    }

    .mobile-navbar-container {
        max-width: 397px;
    }


    .mobile-navbar li a {
        font-size: 20px;
        line-height: 28px;
    }


    .mobile-navbar .dropdown-menu .dropdown-divisi a,
    .mobile-navbar .dropdown-menu li a {
        font-size: 18px;
        line-height: 24px;
    }

    .mobile-navbar #dropdown-menu-divisi a {
        font-size: 16px;
        line-height: 20px;
    }

    .mobile-navbar .dropdown-divisi img {
        max-height: 28px;
        max-width: 28px;
        width: 100%;
        height: 100%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}
