.IG-content{
    display: flex;
    flex-direction: column;
    width: 329px;
    height: 401px;
    padding: 12px;
    border: 2px solid var(--gray-10, #111827);
    background: var(--primary-06, #E49800);
    margin: 46px 10px 10px 10px;
}

.IG-content-img{
    width: 306px;
    height: 172px;
    object-fit: cover;
}

.IG-content-title{
    font-family: Changa One;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-shadow: 1.5px 1.5px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
    color: #f0ebe4;
    margin-top: 11px;
}

.IG-content-category{
    font-family: Changa One;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
    color: #f0ebe4;
    margin: 4px 0px 4px auto;
}

.IG-content-desc{
    color: var(--monochrome-white, #FFF);
    text-align: justify;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.825px;
    letter-spacing: 0.074px;
    margin: 4px 0px 4px 0px;
}

.IG-content-created{
    color: var(--monochrome-white, #FFF);
    text-align: justify;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.825px;
    letter-spacing: 0.074px;
    margin-top: auto;
}

.IG-button{
    display: flex;
    border: #000000 solid 2px;
    width:auto;
    justify-content: center;
    align-items: center;
    padding: 3px;
    background:#E49800;
    box-shadow: 1.5px 1.5px 0px #000000;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.IG-button:active {
    transform: translateY(2px);
    transform: translateX(3px);
    box-shadow: 1px 1px 0px #000000;
}

.IG-button > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.createdby{
    color: var(--monochrome-white, #FFF);
    text-align: justify;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 100;
    line-height: 14.825px;
    letter-spacing: 0.074px;
    align-items: center;
}