#DAMING-header-container {
    background-image: url('../../../Assets/Ilkommunity/DAMING/DAMING-Header-Background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    height: 640px;
    margin-top: .8rem;
    display: flex;
    border-top: 4px solid black;
    border-bottom: 4px solid black;
    justify-content: center;
}

.DAMING-header-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 15.81rem;
}

.DAMING-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    width: 377px;
    height: 208px;
}

.DAMING-header h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;

}

.DAMING-header p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;
}

#DAMING-main-container {
    background-image: url('./../../../Assets/Background/pattern-top.svg'), url('./../../../Assets/Background/pattern-bottom.svg');
    background-position: top left, bottom right;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 80px 0 0 0;
}

.DAMING-content-container{
    overflow: hidden;
}

.DAMING-content-deskripsi-tujuan {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: row; */
    gap: 80px;
}

#DAMING-deskripsi.active,
#DAMING-tujuan.active {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    border-bottom: 4px solid black;
    cursor: pointer;
    padding-bottom: 4px;
}

#DAMING-deskripsi,
#DAMING-tujuan {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #6b7280;
    text-align: center;
    cursor: pointer;
}


.DAMING-content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.DAMING-deskripsi-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E9AD33;
    border: 4px solid #16052B;
    box-shadow: 7px 7px 0px #000000;
    margin: 32px 0 0 0;
    padding: 40px 44px 40px 44px;
}


.DAMING-deskripsi-content p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
}

.DAMING-tujuan-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E9AD33;
    border: 4px solid #16052B;
    box-shadow: 7px 7px 0px #000000;
    margin: 32px 0 0 0;
    padding: 40px 44px 40px 44px;
}

.DAMING-deskripsi-content,
.DAMING-tujuan-content {
    width: 90%;
    max-width: 1057px;
    height: auto;
}

.DAMING-tujuan-content p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
}

.DAMING-prestasi-container {
    margin: 80px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.DAMING-prestasi-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    border: 3px solid #16052B;
    box-shadow: 3px 4px 0px #000000;
    background-color: #FFFFFF;
    padding: 45px;

    width: 90%;
    max-width: 1057px;
    height: auto;

    flex-direction: column;
}

.DAMING-prestasi-content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.DAMING-prestasi-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 52px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 4px 4px 0px #000000;
    transform: translateY(25px);
}

.DAMING-prestasi-header h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.DAMING-prestasi-content li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}


.DAMING-narahubung-container {
    padding: 80px 0 160px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DAMING-narahubung-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    border: 3px solid #16052B;
    box-shadow: 3px 4px 0px #000000;
    background-color: #FFFFFF;

    padding: 45px;
    width: 90%;
    max-width: 1057px;
    height: auto;

}

.DAMING-narahubung-content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.DAMING-narahubung-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 228px;
    height: 52px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 4px 4px 0px #000000;
    transform: translateY(25px);

}

.DAMING-narahubung-header h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.dokumentasi-content-slider {
    width: 1057px;
    height: 704px;
    background: #453755;
    border: 3px solid #1F2937;
    box-shadow: 7px 7px 0px #1F2937;
}

@media screen and (max-width: 1024px){
    .DAMING-header-wrapper{
        column-gap: normal;
    }
}

@media (min-width: 769px) and (max-width: 1023px){
    #DAMING-header-container {
        width: 100%;
        max-width: 1024px;
        height: 500px;
    }
}

@media(max-width: 768px) {
    #DAMING-header-container {
        background-image: url("../../../Assets/Ilkommunity/DAMING/DAMING-Header-Background-Tablet.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 521px;
    }

    .DAMING-header-wrapper {
        width: 670px;
    }

    .DAMING-header{
        width: 377px;
    }

    .DAMING-header p {
        font-size: 16px;
    }

    .DAMING-deskripsi-content,
    .DAMING-tujuan-content,
    .DAMING-prestasi-content,
    .DAMING-narahubung-content {
        width: 90%;
        max-width: 668px;
        height: auto;
    }

    .DAMING-deskripsi-content p,
    .DAMING-tujuan-content p {
        font-size: 16px;
    }
}

@media (min-width: 481px) and (max-width: 767px){
    #DAMING-header-container {
        width: 100%;
        max-width: 768px;
        height: 410px;
    }

    .DAMING-header-wrapper{
        grid-template-columns: none;
    }

    .DAMING-header p{
        width: 80% !important;
    }
}

@media(max-width: 480px) {
    #DAMING-header-container {
        background-image: url("../../../Assets/Ilkommunity/DAMING/DAMING-Header-Background-Phone.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 522px;
    }

    .DAMING-header-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        transform: translateY(6rem);
    }

    .DAMING-header p {
        width: 80% !important;
    }

    .DAMING-header h2 {
        font-size: 45px;
        line-height: 52px;
    }

    .DAMING-prestasi-content li,
    .DAMING-narahubung-content h3 {
        font-size: 16px;
    }

    #DAMING-deskripsi.active {
        font-size: 28px;
        text-shadow: 2px 2px 0px #040404;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #040404;
    }
    #DAMING-tujuan.active {
        font-size: 28px;
        text-shadow: 2px 2px 0px #040404;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #040404;
    }
}