#proker-accordion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 14rem;
    width: 100%;
}

.proker-accordion-wrapper {
    background-color: #FFFFFF;
    border: 2px solid #040404;
    border-radius: 8px;
    max-width: 1065px;
    width: 90%;
}

.proker-accordion-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    cursor: pointer;
    border-bottom: 2px solid #040404;
}

.proker-accordion-title.active{
    background-color: #E9AD33;
}

.proker-accordion-title h2 {
    font-size: 22px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.proker-accordion-title:hover{
    background-color: #E9AD33;
    transition: .4s;
}

.proker-accordion-desc{
    max-height: 0;
    padding: 0;
    overflow: hidden;
    border-bottom: 2px solid #040404;
    transition: 0.05s;
}

.proker-accordion-desc.show{
    height: auto;
    max-height: 99999px;
    overflow: hidden;
    transition: 0.3s cubic-bezier(0,1,0,1);
    padding: 20px 24px;
}
.proker-accordion-desc p {
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media(max-width: 768px) {
    .proker-accordion-wrapper {
        max-width: 688px;
    }
}