.megaproker-header{
   display: flex;
   text-align: center;
   align-items: center;
   justify-content: center; 
   background-image: url("./../../../src/Assets/Background/megaprokerheader.svg");
   background-position: center center;
   min-height: 239px;
   border-top: 4px solid #111827;
   border-bottom: 4px solid #111827;
   margin: 13px 0px 0px 0px;
   background-size: cover;
   padding: 0 2em;
}

.megaproker-header h1{
   font-family: 'Sports World';
   font-style: normal;
   font-weight: 400;
   font-size: 45px;
   line-height: 52px;
   color: #F0E4D4;
   text-shadow: 4px 3px 0px #040404;
   -webkit-text-stroke-width: 1px; 
   -webkit-text-stroke-color: #040404;
}


#Megaproker-Page-Card-Container{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: fit-content;
   padding: 0 81px;
   padding-top: 6.75rem;
}

.Megaproker-Proker-Card{ 
   width: 100%;
}

.Megaproker-Proker-Card-Header{
   width: 100%;
   height: 336px;

   display: flex;
   position: relative;
   background-size: 100% 100%;
   width: 100%;
}

.Megaproker-Proker-Card-Header-bg{
   display: flex;
   flex-direction: column;
   align-items: center;
   background-image: url(./../../Assets/Megaproker/megaproker-card1-header-dekstop.png);
   background-size: 100% 100%;
   height: 398px;
   width: 100%;
   position: absolute;
   z-index: -1;
}

.Megaproker-Proker-Content{
   display: flex;
   position: relative;
   flex-direction: column;
   background-size: 100% 100%;
   width: 100%;
   height: 1104px;
}

.Megaproker-Proker-Card-Content-bg{
   display: flex;
   flex-direction: column;
   align-items: center;
   background-image: url(./../../Assets/Megaproker/megaproker-card1-content-dekstop.png);
   background-size: 100% 100%;
   height: 1104px;
   width: 100%;
   position: absolute;
   z-index: -1;
}

.Megaproker-Card1-logo{
   display: flex;
   align-items: center;
   flex: 40%;
   padding-left: clamp(1rem, 0.1608rem + 4.196vw, 3.9375rem);
}

.Megaproker-Card1-logo img{
    object-fit: contain;
    max-width:472.796px;
    width: 100%;
    height: auto;
}

.Megaproker-Card-info{
   display: flex;
   flex-direction: column;
   flex: 60%;
   padding-left: clamp(1rem, -0.634rem + 8.17vw, 6.71875rem);
}

.Megaproker-Card1-logo, .Megaproker-Card-info {  z-index: 2;}

.Megaproker-Card-info h1 {
   font-family: "Sports World";
   font-style: normal;
   font-weight: 400;
   font-size: 36px;
   line-height: 64px;
   color: #f0e4d4;
   text-shadow: 3px 2px 0px #040404;
   -webkit-text-stroke-width: 2px;
   -webkit-text-stroke-color: #040404;
   
}

.Proker-Card1 h1{
   margin-top: 114px;
}

.Megaproker-Card-info p {
   font-family: 'Montserrat';
   font-weight: 400;
   font-style: normal;
   font-size: 16px;
   font-size: clamp(0.875rem, 0.50012rem + 0.781vw, 1rem);
   line-height: 24px;
   text-align: justify;
   color: #111827;

   width: 75%;
}

.Megaproker-Proker-Content h3{
   margin: 0 auto;
   font-family: 'Changa One';
   font-style: normal;
   font-weight: 400;
   font-size: 36px;
   margin-top: 7.66rem;
}

/* Carousel */
.carousel-container{
   position: relative;
   margin: 0 20px;
}

.Megaproker-Proker-Content .carousel{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;

   /* padding: 0 40px; */
   padding-bottom: 20px;
   width: 100%;
   height: auto;
   
   
   margin-top: 3.06rem;
   overflow-x: scroll;

   /* background-color: aqua; */
}


.Megaproker-Proker-Content .carousel .box img{
   transition: 0.3s;
   object-fit: cover;
   border: 2px solid black;
   
   width: 329.93px;
   height: 188.5px;

   margin: 0 21.5px;
}

.Megaproker-Proker-Content .carousel .box:hover img{
   box-shadow: 10px 10px 0px #000000;
   transform: translateY(-10px);
   transform: translateX(-10px);
   width: 441.53px;
   height: 257.81px;
}

.Megaproker-Proker-Content .dokumentasi-vid-title{
   margin-top: 6.25rem;
}

.dokumentasi-video {
   display: flex;
   flex-direction: row;
   justify-content: center;

   margin-top: 1.69rem;
}

.dokumentasi-video .box img{
   max-width: 638px;
   width: 100%;
   max-height: 307.71px;
   height: 100%;
   border: 2px solid black;
   box-shadow: 10px 10px 0px #000000;
}


#Megaproker-Page-Card-Container button {
   box-sizing: border-box;
   font-family: 'Montserrat';
   font-weight: 700;
   font-style: normal;
   font-size: 22px;
   line-height: 28px;
   padding: 8px 14px 8px 16px;
   background: #E49800;
   border: 1px solid #111827;
   box-shadow: 3px 4px 0px #000000;
   color: var(--monochrome-white, #FFF);

   
   width: 214px;
   height: 44px;
   display: block;

   margin: 0 auto;
   margin-top: 4.27rem;
}

#Megaproker-Page-Card-Container  button:active {
   transform: translateY(2px);
   transform: translateX(3px);
   box-shadow: 1px 1px 0px #000000;
}

.Proker-Card2{
   margin-top: 2.65rem; 
}

.Proker-Card2  .Megaproker-Proker-Card-Header{
   flex-direction: row-reverse;
}

.Proker-Card2 .Megaproker-Proker-Card-Header-bg{
   background-image: url(./../../Assets/Megaproker/megaproker-card2-header-dekstop.png);
}

.Proker-Card2 .Megaproker-Proker-Card-Content-bg{
   background-image: url(./../../Assets/Megaproker/megaproker-card2-content-dekstop.png);
}

.Proker-Card2 .Megaproker-Card-info h1{
   margin-top: 6rem;
}

.Megaproker-Card2-logo{
   display: flex;
   align-items: center;
   justify-content: flex-end;
   flex: 40%;
   padding-right: clamp(0.625rem, -1.25rem + 9.375vw, 7.1875rem);
}

.Megaproker-Card2-logo img{
   object-fit: contain;
   width: 300px;
   height: auto;
   max-width: 100%;
   max-height: 100%;
   border: 3px solid var(--gray-10, #111827);
   box-shadow: 10px 10px 0px #000000;
}

#button-etc{
   margin-bottom: 8.62rem;
}

#Megaproker-Page-Card-Container #button-etc button {
   width: 220px;
   height: 48px;
   line-height: 32px;
}


@media (max-width:1024px) {
   #Megaproker-Page-Card-Container{
      padding-left:  clamp(2.5rem, -5.0936rem + 15.82vw, 5.03125rem);
      padding-right: clamp(2.5rem, -5.0936rem + 15.82vw, 5.03125rem);
   }
}

@media(max-width: 768px) {
   #Megaproker-Page-Card-Container{
      padding-top: 2rem;
   }

   .Megaproker-Proker-Card-Header{
      height:296.25px;
   
      flex-direction: column;
      align-items: center;

   }

   .Megaproker-Proker-Card-Header-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card1-header-tablet.png);
      height: 382.22px;
   }
   
   .Megaproker-Card1-logo{
      align-items: center;
      flex: 0%;
      padding-left: 0;
      padding-top:2.72rem ;
   }
   
   .Megaproker-Card1-logo img{
       object-fit: contain;
       max-width:253.93px;
       width: 100%;
       height: auto;
   }

   .Proker-Card1 h1{
      margin-top: clamp(0.9375rem, 0.3126rem + 2.083vw, 1.3125rem);
   }

   .Megaproker-Card-info{
      flex: 100%;
      padding-left: 0;
   }

   .Megaproker-Card-info h1 {
      font-size: 24px;
      line-height: 35.974px;
      text-shadow: 3px 2px 0px #040404;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #040404;

      text-align: center;
   }

   .Megaproker-Card-info p {
      font-size: 18px;
      font-size: clamp(0.875rem, 0.50012rem + 0.781vw, 1rem);
      line-height: 23.983px;
      text-align: center;
      font-weight: 700;
      margin: 0 auto;
      width: 100%;
   }
  
   .Megaproker-Proker-Content{
      height: 900.85px;
   }

   .Megaproker-Proker-Card-Content-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card1-content-tablet.png);
      height: 900.85px;
   }

   .Megaproker-Proker-Content h3{
      margin: 0 auto;
      font-weight: 400;
      font-size: 24px;
      line-height: 35.974px; 
      margin-top: 5.71rem;
   }

   /* Carousel  */
   .Megaproker-Proker-Content .carousel{   
      margin-top:0.84rem;
      padding-bottom: 3%;
   }
   
   .Megaproker-Proker-Content .carousel .box img{
      width: 325.26px;
      height:217.34px;
      transition: 0.3s;
      object-fit: cover;
      border: 2px solid black;
   }
   
   .Megaproker-Proker-Content .carousel .box:hover img{
      box-shadow: 10px 10px 0px #000000;
      transform: translateY(-10px);
      transform: translateX(-10px);
      width: 379.9px;
      height: 253.85px;
   }

   .Megaproker-Proker-Content .dokumentasi-vid-title{
      margin-top: 3rem;
   }

   .dokumentasi-video {
      margin-top: 0.84rem;
      padding: 0 10%;
   }

   .dokumentasi-video .box img{
      max-width: 471.05px;
      max-height:265.64px;
      width: 100%;
      height: 100%;
      border: 2px solid black;
      box-shadow: 8px 8px 0px #000000;
   }

   #Megaproker-Page-Card-Container button {
      display: flex;
      justify-content: space-between;

      font-size: 16.488px;
      line-height: 23.983px;
      letter-spacing: 0.749px;

      padding: 8px 14px 8px 16px;
      background: #E49800;
      border: 1px solid #111827;
      box-shadow: 3px 4px 0px #000000;
      color: var(--monochrome-white, #FFF);

      min-width: 173.87px;
      width: auto;
      height:37.47px;
      display: block;

      margin-top: 3.09rem;
   }

   /* Proker Card 2 */
   .Proker-Card2{
      margin-top: 4.49rem; 
   }
   
   .Proker-Card2  .Megaproker-Proker-Card-Header{
      flex-direction: column;
   }
   
   .Proker-Card2 .Megaproker-Proker-Card-Header-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card2-header-tablet.png);
   }
   
   .Proker-Card2 .Megaproker-Proker-Card-Content-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card2-content-tablet.png);
   }
   
   .Proker-Card2 .Megaproker-Card-info h1{
      margin-top: 0.8rem;
   }
   
   .Proker-Card2   .Megaproker-Card-info p{
      width: 80%;
   }

   .Megaproker-Card2-logo{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 100%;
      padding-right: 0;
      padding-top: 1.03rem;
   }
   
   .Megaproker-Card2-logo img{
      width: 166.38px;
      border: 3px solid var(--gray-10, #111827);
      box-shadow: 5px 5px 0px #000000;
   }
   
   #button-etc{
      margin-bottom: 5rem;
   }
   
   #Megaproker-Page-Card-Container #button-etc button {
      width: 220px;
      height: 48px;
      line-height: 32px;
   }
   
   .dokum-img-3{
      margin-right: 10%;
   }

}

@media (max-width: 480px) {   
   .megaproker-header h1{
      font-size: 28px;
      line-height: 36px;
      color: #F0E4D4;
      text-shadow: 3px 3px 0px #040404;
      -webkit-text-stroke-width: 1.5px; 
      -webkit-text-stroke-color: #040404;
   }

   #Megaproker-Page-Card-Container{
      padding:  0 0.63rem;
      padding-top: 1.81rem;
   }

   .Megaproker-Proker-Card-Header{
      height:196.16px;
   }

   .Megaproker-Proker-Card-Header-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card1-header-tablet.png);
      height: 251px;
   }
   .Megaproker-Card1-logo{
      padding-top:1.04rem ;
   }

   .Megaproker-Card1-logo img{
      object-fit: contain;
      max-width:169.41px;
      width: 100%;
      height: auto;
  }

   .Megaproker-Card-info h1 {
      font-size: 16px;
      line-height: 24px;
      text-shadow: 1.5px 1px 0px #040404;
      -webkit-text-stroke-width:1px;
      -webkit-text-stroke-color: #040404;

      text-align: center;
   }

   .Megaproker-Card-info p {
      font-size: 12px;
      line-height: 16px;
      width: 98%;
   }

   .Megaproker-Proker-Content{
      height: 601px;
   }

   .Megaproker-Proker-Card-Content-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card1-content-phone.png);
      height:601px;
   }

   .Megaproker-Proker-Content h3{
      font-size: 16px;
      line-height: 24px;
      margin-top: 3.94rem;
   }

   /* Carousel  */
   .Megaproker-Proker-Content .carousel{   
      margin-top:0.56rem;
      padding-bottom: 5%;
      padding-top: 1%;
   }
   
   .Megaproker-Proker-Content .carousel .box img{
      margin: 0 30px;

      width: 217px;
      height: 145px;
      transition: 0.3s;
      object-fit: cover;
      border: 2px solid black;
   }
   
   .Megaproker-Proker-Content .carousel .box:hover img{
      box-shadow: 5px 5px 0px #000000;
      transform: translateY(-10px);
      transform: translateX(-10px);
      width: 227.26px;
      height: 151.86px;
   }

   .Megaproker-Proker-Content .dokumentasi-vid-title{
      margin-top: 1.5rem;
   }

   .dokumentasi-video {
      margin-top: 0.56rem;
      padding: 0 10%;
   }

   .dokumentasi-video .box img{
      max-width: 314.26px;
      max-height:177.22px;
      width: 100%;
      height: 100%;
      border: 2px solid black;
      box-shadow: 5px 5px 0px #000000;
   }

   #Megaproker-Page-Card-Container button {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;

      padding:8px 5px 8px 16px;
      background: #E49800;
      border: 1px solid #111827;
      box-shadow: 3px 4px 0px #000000;
      color: var(--monochrome-white, #FFF);

      min-width: 116px;
      width: auto;
      height:auto;
      display: flex;

      margin-top: 2rem;
   }

   #Megaproker-Page-Card-Container button img{
      margin-left: 8px;
   }


   /* Proker 2 */
   .Proker-Card2{
      margin-top: 1.56rem; 
   }
   
   .Proker-Card2  .Megaproker-Proker-Card-Header{
      flex-direction: column;
   }
   
   .Proker-Card2 .Megaproker-Proker-Card-Header-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card2-header-phone.png);
   }
   
   .Proker-Card2 .Megaproker-Proker-Card-Content-bg{
      background-image: url(./../../Assets/Megaproker/megaproker-card2-content-phone.png);
   }
   
   .Megaproker-Card2-logo{
      flex: 100%;
      padding-right: 0;
      padding-top: 0.69rem;
   }
   
   .Megaproker-Card2-logo img{
      max-width: 111px;
      width: 100%;
      max-height: 111px;
      height: auto;
      border: 3px solid var(--gray-10, #111827);
      box-shadow: 5px 5px 0px #000000;
   }
   
   .Proker-Card2 .Megaproker-Card-info h1{
      margin-top: 0.8rem;
   }
   
   .Proker-Card2   .Megaproker-Card-info p{
      width: 90%;
      text-align: right;
   }
   
   #Megaproker-Page-Card-Container #button-etc{
      margin-top:1.56rem;
   }

   #Megaproker-Page-Card-Container #button-etc button {
      width: 142px;
      height: 36px;
      font-size: 14px;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.1px;  
      margin-top: 0;
   }
}


@media (max-width: 380px) {
   .Megaproker-Card2-logo img{
      max-width: 90px;
      width: 100%;
      max-height: 90px;
      height: auto;
      border: 3px solid var(--gray-10, #111827);
      box-shadow: 5px 5px 0px #000000;
   }

 }