.ristek-container {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 737px;
    background-image: url("./../../../../Assets/Divisi/Ristek/ristek-desktop-header.png"), url("./../../../../Assets/Divisi/Ristek/desk_background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    border-top: 5px solid #000000;
    border-bottom: 5px solid #000000;
    margin-top: 13px
}

.logo-container-ristek {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 50%;
}

.desc-ristek {
    flex: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.desc-ristek h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    margin: 0 0 29px 0;

    text-shadow: 7px 4px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
}

.desc-ristek p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    width: 75%;
}

.ristek-staff-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0 0 0;
}

.ristek-staff-header h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    padding-bottom: 14px;

    text-shadow: 7px 4px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    padding: 0 100px 20px 100px;
    border-bottom: 8px solid #000000;
}

.stafff {
    background-repeat: no-repeat;
    background-image: url('./../../../../Assets/Background/pattern-top.svg'), url('./../../../../Assets/Background/pattern-bottom.svg');
    background-position: top left, bottom right;
    background-size: contain;
}

.ristek-anggota {
    overflow: hidden ;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: center;
    gap: 80px 120px;
    padding: 76px 116px 0px 106px;
}

.anggota-divisi-ristek:first-child {
    grid-column: span 3;
}

.anggota-divisi-ristek:last-child {
    grid-column: span 1;
}


.program-header-ristek {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 160px 0 40px 0;
}

.program-header-ristek h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    text-shadow: 7px 4px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    padding: 0 16px 20px 16px;
    border-bottom: 8px solid #000000;
}

.program-header-ristek img {
    width: 572px;
}


.ristek-proker-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    cursor: pointer;
    border-bottom: 2px solid #040404;
}

.ristek-proker-title.active {
    background-color: #E9AD33;
}

.ristek-proker-title h2 {
    font-size: 22px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.ristek-proker-title:hover {
    background-color: #E9AD33;
    transition: .4s;
}

.ristek-proker-desc {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    border-bottom: 2px solid #040404;
    transition: 0.05s;
}

.ristek-proker-desc.show {
    height: auto;
    max-height: 99999px;
    overflow: hidden;
    transition: 0.3s cubic-bezier(0, 1, 0, 1);
    padding: 20px 24px;
}

.ristek-proker-desc p {
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media(max-width:1280px) {
    .ristek-container {
        height: 100vh;
    }

    .desc-ristek h2 {
        font-size: 42px;
        text-shadow: 4px 3px 0px #040404;
    }

    .ristek-anggota {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .anggota-divisi-ristek img {
        height: 448.37px;
    }

    .anggota-divisi-ristek:first-child {
        grid-column: span 2;
    }

    .anggota-divisi-ristek:last-child {
        grid-column: span 2;
    }

}

@media(max-width: 768px) {

    .ristek-container {
        background-image: url("../../../../Assets/Divisi/Ristek/ristek-tab-header.png"), url("../../../../Assets/Divisi/Ristek/Ristek tab Bg.svg");
        height: 549px;

    }

    .logo-container-ristek {
        flex: 70% 1;
    }

    .desc-ristek h2 {
        font-size: 45px;
        line-height: 52px;
        letter-spacing: -2px;
    }

    .desc-ristek p {
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        width: 90%;
    }

    .ristek-staff-header h2 {
        font-size: 45px;
        padding: 0 50px 4px 50px;
        border-bottom: 6px solid #000000;
    }

    .ristek-anggota {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 44.23px 42px 0px 42px;
        gap: 40px;
        overflow-x: hidden;
    }

    .anggota-divisi-ristek:first-child {
        grid-column: span 3;
    }

    .anggota-divisi-ristek:last-child {
        grid-column: span 3;
    }

    .anggota-divisi-ristek img {
        height: 240.13px;
    }

    .program-header-ristek{
        padding: 87px 0 40px 0;
    }

    .program-header-ristek h2 {
        font-size: 45px;
        border-bottom: 6px solid #000000;
        padding: 0 16px 10px 16px;
    }
}

@media(max-width: 640px) {
    .ristek-anggota{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .anggota-divisi-ristek:first-child {
        grid-column: span 2;
    }

    .anggota-divisi-ristek:last-child {
        grid-column: span 2;
    }
}

@media screen and (max-width: 480px) {

    .ristek-container {
        background-image: url("../../../../Assets/Divisi/Ristek/ristek-mobile-header.png"), url("../../../../Assets/Divisi/Ristek/Ristek Mobile Bg.svg");
        height: 522px;
    }

    .logo-container-ristek {
        display: none;
    }

    .desc-ristek {
        flex: 100% 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .desc-ristek p {
        text-align: center;
        font-size: clamp(0.875rem, 0.625rem + 1.25vw, 1rem);
        line-height: 22px;
    }

    .ristek-anggota {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding: 44.23px  clamp(0.75rem, -3rem + 18.75vw, 2.625rem);
        padding-bottom:0;
        gap: 40px 10px;
    }


    .desc-ristek h2 {
        font-size: 29px;
        line-height: 40px;
        margin: 190px 0 9px 0;
        letter-spacing: -2px;
        text-align: center;
    }

    .anggota-divisi-ristek img {
        max-height: 235.88px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .anggota-divisi-ristek:first-child {
        grid-column: span 2;
    }

    .anggota-divisi-ristek:last-child {
        grid-column: span 2;
    }

    .program-header-ristek {
        padding: 80px 0 40px 0;
    }

    .program-header-ristek h2 {
        border-bottom: 3px solid #000000;
        text-shadow: 3px 2px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
        line-height: 28px;
        font-size: 22px;
        padding: 0 4px 0px 4px;
    }

    .program-header-ristek img {
        display: none;
    }

    .ristek-staff-header h2 {
        text-shadow: 3px 2px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
        line-height: 28px;
        font-size: 22px;
        padding: 0 10px 0px 10px;
        border-bottom: 3px solid #000000;
    }

    .ristek-staff-header img {
        display: none;
    }

    .program-header-ristek h2{
        border-bottom: 3px solid #000000;
        text-shadow: 3px 2px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
        line-height: 28px;
        font-size: 22px;
        padding: 0 4px 0px 4px;
    }
}


@media screen and (max-width: 380px) {
    .anggota-divisi-ristek:first-child img, .anggota-divisi-ristek:last-child img{
        padding: 0 80px;
    }

    .desc-ristek p {
        line-height: 18px;
    }
}