#UX-header-container {
    background-image: url('../../../Assets/Ilkommunity/UX/UX-Header-Background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    height: 640px;
    justify-content: center;
    margin-top: .8rem;
    border-top: 4px solid black;
    border-bottom: 4px solid black;
}

.UX-header-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 15.81rem;
}

.UX-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 377px;
    height: 208px;
}

.UX-header h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
}

.UX-header p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.UX-content-container{
    overflow: hidden;
}

#UX-main-container {
    background-image: url('./../../../Assets/Background/pattern-top.svg'), url('./../../../Assets/Background/pattern-bottom.svg');
    background-size: contain;
    background-position: top right, bottom left;
    background-repeat: no-repeat;
    padding: 80px 0 0 0;
}

.UX-content-deskripsi-tujuan {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 80px;
}

.UX-content-deskripsi-tujuan #UX-deskripsi {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #6B7280;
    cursor: pointer;
    text-align: center;
}

#UX-deskripsi.UX-active {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    border-bottom: 4px solid black;
    cursor: pointer;
    padding-bottom: 4px;
}

#UX-tujuan {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #6B7280;
    cursor: pointer;
    text-align: center;
}

#UX-tujuan.UX-active {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    border-bottom: 4px solid black;
    cursor: pointer;
    padding-bottom: 4px;
}

.UX-content-deskripsi-tujuan {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #6B7280;
    cursor: pointer;
    text-align: center;
}

.UX-content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.UX-deskripsi-content, .UX-tujuan-content{
    max-width: 1057px;
    width: 90%;
    height: auto;
}

.UX-deskripsi-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E9AD33;
    border: 4px solid #16052B;
    box-shadow: 7px 7px 0px #000000;
    margin: 32px 0 0 0;
    padding: 40px 44px 40px 44px;
    margin-left: auto;
    margin-right: auto;
}


.UX-deskripsi-content p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
}

.UX-tujuan-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E9AD33;
    border: 4px solid #16052B;
    box-shadow: 7px 7px 0px #000000;
    margin: 32px 0 0 0;
    padding: 40px 44px 40px 44px;
}


.UX-tujuan-content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
}


.UX-prestasi-container {
    margin: 80px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.UX-prestasi-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    max-width: 1060px;
    width: 90%;
    padding: 45px;
    border: 3px solid #16052B;
    box-shadow: 3px 4px 0px #000000;
    background-color: #FFFFFF;
}

.UX-prestasi-content li {
    color: #010101;
    text-align: center;
    font-size: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.UX-prestasi-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 52px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 4px 4px 0px #000000;
    transform: translateY(25px);
}

.UX-prestasi-header h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.UX-narahubung-container {
    padding: 80px 0 160px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.UX-narahubung-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    max-width: 1060px;
    width: 90%;
    height: 174px;
    border: 3px solid #16052B;
    box-shadow: 3px 4px 0px #000000;
    background-color: #FFFFFF;
}

.UX-narahubung-content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.UX-narahubung-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 228px;
    height: 52px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 4px 4px 0px #000000;
    transform: translateY(25px);
}

.UX-narahubung-header h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

@media(max-width: 1024px) {

    .UX-header {
        width: auto;
        height: auto;
    }

    .UX-header-wrapper {
        column-gap: normal;
    }

    .UX-header p {
        font-size: large;
        width: auto;
    }

    .UX-deskripsi-content {
        width: 90%;
        height:auto;
    }


    .UX-tujuan-content {
        width: 90%;
        height: auto;
    }

    .UX-prestasi-content {
        width: 90%;
        height: auto;
    }

    .UX-narahubung-content {
        width: 90%;
        height: 190px;
    }
}

@media (min-width: 769px) and (max-width: 1023px){
    #UX-header-container {
        width: 100%;
        max-width: 1024px;
        height: 500px;
    }
}

@media only screen and (max-width:768px) {
    #UX-header-container {
        background-image: url('../../../Assets/Ilkommunity/UX/UX-Header-Background-Tab.svg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        justify-content: center;
        height: 521px;
    }

    .UX-header-wrapper {
        width: 100%;
        max-width: 670px;
    }

    .UX-header{
        width: 100%;
        max-width: 377px;
    }

    .UX-header p {
        font-size: 16px;
        width: auto;
    }

    .UX-deskripsi-content {
        width: 90%;
        max-width: 668px;
        height: auto;
    }

    .UX-tujuan-content {
        width: 90%;
        max-width: 668px;
        height: auto;
    }


    .UX-prestasi-content {
        width: 90%;
        max-width: 668px;
        height: auto;
    }

    .UX-prestasi-content li {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
    }

    .UX-narahubung-content {
        width: 90%;
        max-width: 668px;
        height: 190px;
    }
}

@media (min-width: 481px) and (max-width: 767px){
    #UX-header-container {
        width: 100%;
        max-width: 768px;
        height: 410px;
    }

    .UX-header-wrapper{
        grid-template-columns: none;
    }

    .UX-header p{
        width: 80%;
    }
}

@media screen and (max-width: 480px) {
    #UX-header-container {
        background-image: url("../../../Assets/Ilkommunity/UX/UX-Header-Background-Mobile.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        justify-content: center;
        height: 522px;
        border-top: 4px solid #111827;
        border-bottom: 4px solid #111827;
        align-items: unset;
    }

    #UX-deskripsi, #UX-tujuan{
        font-size: 22px;
        line-height: 28px;
        overflow-x: hidden;
    }

    .UX-header-wrapper {
        grid-template-columns: none;
        width: auto;   
        align-items: end;
        padding-bottom: 2.7rem;
    }

    .UX-header h2 {
        font-size: 45px;
    }

    .UX-header p{
        width: 80%;
    }

    .UX-deskripsi-content {
        width: 90%;
        height: auto;
    }

    .UX-tujuan-content {
        width: 90%;
        height: auto;
    }

    .UX-prestasi-content {
        width: 90%;
        height: auto;
    }

    .UX-narahubung-content {
        width: 90%;
        height: 190px;
    }

    #UX-deskripsi.UX-active {
        font-size: 28px;
        text-shadow: 2px 2px 0px #040404;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #040404;
    }
    #UX-tujuan.UX-active {
        font-size: 28px;
        text-shadow: 2px 2px 0px #040404;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #040404;
    }
}