.profil-himalkom-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url('../../../Assets/Profil Himalkom/profil-himalkom-hero-desktop.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 738px;
  margin-top: 0.75rem;
}
/* Changes */
.inner-container {
  display: flex;
  flex-direction: column;
}
.outer-container {
  display: flex;
  flex-direction: column;
}
/* --------------------------- */
.profil-himalkom-hero h1 {
  color: #F0E4D4;
  font-family: Sports World;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;

  text-shadow: 3px 5px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
}

.profil-himalkom-hero h3 {
  color: #F0E4D4;
  text-align: center;
  /* Changa One/Italic/Headline Large */
  font-family: Changa One;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
  text-shadow: 3px 3px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
}

.profil-himalkom-hero p {
  color: #F0E4D4;
  text-align: center;
  font-family: Changa One;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  text-shadow: 2px 4px 0px #040404;

  padding: 2.31rem 0 0 0;
}

.Himalkom-intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 669px;
  background-image: url('../../../Assets/Profil Himalkom/Intro-Himalkom-Desktop.svg');
  background-size: cover;
  background-color: #A29BAA;
  background-repeat: no-repeat;


  border-top: 5px solid #111827;
  border-bottom: 5px solid #111827;
  margin: 1rem 0 0 0;
}

.Himalkom-intro-wrapper {
  padding: 0 17.31rem 0 5rem;
}

.Himalkom-intro-header p {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.Himalkom-intro-header h1 {
  color: #F0E4D4;
  font-family: Sports World;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;

  text-shadow: 3px 8px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
}

.Himalkom-intro-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.56rem 0 0 3.38rem;
  gap: 4.38rem;
}

.Himalkom-intro-content p {
  width: 541px;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.Himalkom-intro-content b {
  color: #111827;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.tujuan-himalkom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 464px;
  background-color: #E5E7EB;
  background-image: url('../../../Assets/Profil Himalkom/Tujuan-Himalkom-Desktop.svg');
  background-repeat: no-repeat;
  border-top: 5px solid #111827;
  border-bottom: 5px solid #111827;
  margin: 1rem 0 1rem 0;
}

.tujuan-himalkom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 5rem 0 0;
}

.tujuan-himalkom-wrapper h3 {
  color: #111827;
  text-align: right;
  /* Montserrat/Bold/Headline Small */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 0.62rem;
}

.tujuan-himalkom-wrapper h2 {
  color: #F0E4D4;
  text-align: right;
  font-family: Sports World;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-shadow: 2px 5px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
  padding-bottom: 5.13rem;
}

.tujuan-himalkom-wrapper p {
  width: 100%;
  max-width: 925px;
  color: #170F00;
  text-align: right;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.divisi-container {
  border-width: 5px 0px;
  border-style: solid;
  border-color: #111827;
  margin: 0 0 24px 0;
  background-color: #e49800;
}


.divisi-card {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  justify-items: center;
  width: 100%;
  padding: 0 79px 120px 87px;
  row-gap: 50px;
  column-gap: 26px;
}

.divisi-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 0 82px 0;
}

.divisi-header p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.divisi-header h2 {
  font-family: "Sports World";
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 64px;
  color: #f0e4d4;
  text-shadow: 3px 2px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
  padding: 0 8px 14px 8px;
  border-bottom: 12px solid #040404;
}

.divisi-background {
  background: url("../../../Assets/Profil Himalkom/Aset Divisi/Divisi-Backgound1.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.divisi {
  border: 5px solid transparent;
  transition: 0.3s;
}

.divisi img {
  width: 100%;
  max-width: 350px;
}

.divisi:hover {
  border: 5px solid #16052b;
  box-shadow: 7px 9px 0px #16052b;
  transform: translateY(-10px);
  transform: translateX(-18px);
  background-image: url("../../../Assets/Profil Himalkom/Aset Divisi/Hover-Background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e49800;
}

.punchline {
  display: flex;
  justify-content: center;
  margin: 58px 0 72px 0;
}

.punchline h2 {
  font-family: "Changa One";
  font-style: italic;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #e49800;
  text-shadow: 3px 2px 0px #040404;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #040404;
}

.filop {
  display: flex;
  justify-content: space-around;
  background-image: url("./../../../Assets/Background/Rectangle\ 73.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 784px;
  border-top: 4px solid;
  border-bottom: 4px solid;
  text-align: center;
}

.filop-tengah {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filop-tengah>h1 {
  font-family: "Sports World";
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 64px;
  letter-spacing: -0.25px;
  color: #f0e4d4;
  text-shadow: 3px 4px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
}

.filop-tengah>h3 {
  display: block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  line-height: 32px;
  margin-top: 29px;
}

.logo-image {
  width: 15rem;
  margin-top: 20px;
}

.c-image {
  align-items: center;
  width: 100%;
  max-width: max-content;
  margin-top: 100px;
  margin-bottom: 25px;
  padding: 0 1rem 0 1rem;
}

.filop-kiri {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.filop-kanan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.atas {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F7C5A1;
  padding: 25px;
  border-radius: 24px;
  width: 100%;
  max-width: 290px;
  min-width: 230px;
  min-height: 320px;
  margin-bottom: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 3ms;
  h1 {
    font-size: 30px;
  }
  img {
    margin: 0;
    width: 120px;
  }
}

.bawah {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F7C5A1;
  border-radius: 24px;
  padding: 25px;
  width: 100%;
  max-width: 232px;
  min-width: 195px;
  min-height: 232px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  justify-content: center;
}

.log-1 {
  width: 120px;
  margin-bottom: 20px;
}

.log-2 {
  width: 80px;
  margin-bottom: 20px;
}

.title {
  font-family: 'Changa One';
  font-style: italic;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #EC6F13;
  text-shadow: 3.2px 1.2px 0px #040404;
  margin-bottom: 20px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #040404;
}

.desc {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #000000;
}

/* apa itu HIMALKOM */

.apa-itu-himalkom {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 669px;
  margin-bottom: 16px;
  background-color: #A29BAA;
  background-image: url("../../../Assets/Profil Himalkom/apa-itu-himalkom.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-left: 80px;
  padding-right: 80px;
  border-bottom: 5px solid;
}

.apa-itu-himalkom .txt-container-aph {
  flex: 46%;
}

.apa-itu-himalkom .txt-container-aph h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  color: #111827;
  padding-top: 94px;
  padding-bottom: 10px;
}

.apa-itu-himalkom .txt-container-aph h2 {
  font-family: "Sports World";
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 64px;
  letter-spacing: -0.25px;
  color: #f0e4d4;
  text-shadow: 3px 5px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
  margin-top: 0px;
}

.apa-itu-himalkom .txt-container-aph .logo-Himalkom-img {
  width: 294px;
  padding-top: 67px;
}

.apa-itu-himalkom .text-p {
  flex: 46%;
}

.apa-itu-himalkom .text-p p {
  width: 45%;
  font-weight: 400;
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 32px;
  color: #111827;
  margin-top: 340px;
  margin-left: 150px;
}

.apa-itu-himalkom .text-p span {
  font-weight: 900;
}


/* Tujuan himalkom */
.tujuan-himalkom {
  height: 464px;
  margin-top: 16px;
  margin-bottom: 24px;
  background-color: #e5e7eb;
  border-bottom: 5px solid #040404;
  border-top: 5px solid #040404;
  background-image: url("../../../Assets/Profil Himalkom/logohima_cropped.svg");
  background-repeat: no-repeat;
  background-position: left bottom;

}

.tujuan-himalkom .txt-container {
  display: flex;
  flex-direction: column;
  margin: 70px 80px 110px 80px;
}

.txt-container h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #111827;
}

.txt-container h2 {
  font-family: "Sports World";
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 64px;
  text-align: right;
  letter-spacing: -0.25px;
  color: #f0e4d4;
  text-shadow: 3px 5px 0px #040404;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #040404;
  margin-top: 10px;
}

.txt-container p {
  display: flex;
  margin-left: auto;
  margin-top: 70px;
  width: 70%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: right;
  color: #170f00;
}

@media (min-width:769px) and (max-width: 1024px) {
  .Himalkom-intro-content{
    gap: 2.38rem;
  }

  .Himalkom-intro-wrapper{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .tujuan-himalkom-wrapper {
    padding: 0 2.5rem 0 0;
    max-width: 542px;
  }
  /* Changes */
  .inner-container {
    display: flex;
    flex-direction: column;
  }
  .outer-container {
    display: flex;
    flex-direction: column;
    p {
      font-size: 24px;
    }
  }
  .profil-himalkom-img img {
    width: 180px;
  }
  .inner-container {
    img {
      width: 330px;
    }
    h3 {
      font-size: 20px;
    }
  }
  /* --------------------------- */
}

@media screen and (max-width: 768px) {

  .profil-himalkom-hero {
    background-image: url('../../../Assets/Profil Himalkom/profil-himalkom-hero-tab.svg');
    background-size: cover;
    background-position: center center;
    height: 554.011px;
  }

  .Himalkom-intro-wrapper {
    padding: 0 0 0 2.5rem;
  }

  .Himalkom-intro-content {
    padding: 4.56rem 0 0 0;
  }

  .Himalkom-intro-content p {
    width: 100%;
    padding-right: 7px;
    font-size: 16px;
    max-width: 405px;
  }

  .Himalkom-intro-content b{
    font-size: 20px;
  }

  .Himalkom-intro-content img {
    width: 233.143px;
    height: 232.195px;
  }

  .tujuan-himalkom-container {
    height: 372px;
    background-image: url('../../../Assets/Profil Himalkom/Tujuan-Himalkom-Tab.svg');
  }

  .tujuan-himalkom-wrapper {
    padding: 0 2.5rem 0 0;
    width: 100%;
    max-width: 542px;
  }

  .tujuan-himalkom-wrapper h2 {
    padding-bottom: 2.88rem;
  }

  .tujuan-himalkom-wrapper p {
    width: 542px;
    font-family: Montserrat;
    font-size: 16px;
  }


  .filop {
    background-image: url('../../../Assets/Profil Himalkom/filosofi-himalkom-tab-bg.svg');
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    height: 1275px;
    padding: 0 0 6.69rem 0;
  }

  .filop-tengah {
    grid-column: span 2;
  }

  .filop-kiri {
    order: 1;
  }

  .filop-kanan {
    order: 3;
  }

  .divisi-card {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

    /* Changes */
    .inner-container {
      display: flex;
      flex-direction: column;
    }
    .outer-container {
      display: flex;
      flex-direction: column;
      p {
        font-size: 24px;
      }
    }
    .profil-himalkom-img img {
      width: 180px;
    }
    .inner-container {
      img {
        width: 330px;
      }
      h3 {
        font-size: 20px;
      }
    }
    /* --------------------------- */
}

@media screen and (min-width: 481px) and (max-width: 620px) {
  .profil-himalkom-hero {
    background-image: url('../../../Assets/Profil Himalkom/profil-himalkom-hero-mobile.svg');
    background-size: cover;
    height: 365.934px;
  }

  .profil-himalkom-hero h1 {
    font-size: 28.263px;
    text-shadow: 2px 3px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
  }

  .profil-himalkom-hero h3 {
    font-size: 28.263px;
    text-shadow: 2px 3px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
  }

  .profil-himalkom-hero p {
    font-size: 11.9px;
    text-shadow: 2px 3px 0px #040404;

  }

  .c-image{
    width: 80%;
  }

  .profil-himalkom-img img {
    width: 238.006px;
    height: 143.795px;
  }


  .Himalkom-intro-header h1{
    font-size: 45px;
    text-shadow: 4px 5px 0px #040404;
  }

  .Himalkom-intro-content{
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2.38rem;
  }

  .tujuan-himalkom-container{
    background-image: url('../../../Assets/Profil Himalkom/Tujuan-Himalkom-Mobile.svg');
  }

  .tujuan-himalkom-wrapper h2{
    font-size: 45px;
  }

  .tujuan-himalkom-wrapper p{
    width: 400px;
  }

  .punchline h2 {
    font-size: 24px;
    text-shadow: 1px 2px 0px #040404;
  }

  .filop {
    background-image: url('../../../Assets/Profil Himalkom/filosofi-himalkom-tab-bg.svg');
    background-size: contain;
    background-position: top;
    background-color: #F7C5A1;
    display: flex;
    flex-direction: column;
    padding: 0 0 6.69rem 0;
    height: auto;
  }

  .filop-tengah {
    grid-column: unset;
    .logo-image {
      width: 150px;
    }
  }


  .filop-kiri {
    padding-top: 2.13rem;
  }

  .filop-kanan {
    padding-top: 4.86rem;
  }
    /* Changes */
    .inner-container {
      display: flex;
      flex-direction: column;
    }
    .outer-container {
      display: flex;
      flex-direction: column;
      p {
        font-size: 14px;
        padding: 0;
      }
    }
    .middle-container {
      gap: 0;
      height: 120px;
    }
    .profil-himalkom-img img {
      width: 120px;
    }
    .inner-container {
      img {
        width: 190px;
      }
      h1 {
        height: 60px;
      }
      h3 {
        font-size: 12px;
      }
    }
  
    .atas, .bawah {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #F7C5A1;
      padding: 25px;
      border-radius: 24px;
      width: 100%;
      max-width: 290px;
      min-width: 230px;
      min-height: 320px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 3ms;
      h1 {
        font-size: 30px;
      }
      img {
        margin: 0;
        width: 120px;
      }
    }
    /* --------------------------- */
}


@media (max-width: 480px) {
  .profil-himalkom-hero {
    background-image: url('../../../Assets/Profil Himalkom/profil-himalkom-hero-mobile.svg');
    background-size: cover;
    height: 365.934px;
  }

  .divisi-header h2 {
    font-size: 48px;
    text-shadow: 3px 2px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
  }


  .profil-himalkom-hero h1 {
    font-size: 28.263px;
    text-shadow: 2px 3px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
  }

  .profil-himalkom-hero h3 {
    font-size: 22px;
    text-shadow: 2px 3px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
  }

  .profil-himalkom-hero p {
    font-size: 11.9px;
    text-shadow: 2px 3px 0px #040404;

  }

  .c-image{
    width: 80%;
  }

  .profil-himalkom-img img {
    width: 100%;
    max-width: 238.006px;
  }


  .Himalkom-intro-header h1{
    font-size: 45px;
    text-shadow: 4px 5px 0px #040404;
  }

  .Himalkom-intro-content{
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2.38rem;
  }

  .Himalkom-intro-content b{
    font-size: 20px;
  }

  .tujuan-himalkom-container{
    background-image: url('../../../Assets/Profil Himalkom/Tujuan-Himalkom-Mobile.svg');
  }

  .tujuan-himalkom-wrapper{
    padding-right: 1rem;
  }

  .tujuan-himalkom-wrapper h2{
    font-size: 45px;
    padding-bottom: 1rem;
  }

  .tujuan-himalkom-wrapper p{
    max-width: 400px;
    width: 100%;
  }

  .punchline h2 {
    font-size: 20px;
  }

  .filop {
    background-image: url('../../../Assets/Profil Himalkom/filosofi-himalkom-mobile-bg.svg');
    display: flex;
    flex-direction: column;
    padding: 0 0 6.69rem 0;
    height: auto;
  }

  .filop-tengah {
    grid-column: unset;
  }


  .filop-kiri {
    padding-top: 2.13rem;
  }

  .filop-kanan {
    padding-top: 4.86rem;
  }
  /* Changes */
  .inner-container {
    display: flex;
    flex-direction: column;
  }
  .outer-container {
    display: flex;
    flex-direction: column;
    p {
      font-size: 14px;
      padding: 0;
    }
  }
  .middle-container {
    gap: 0;
    height: 120px;
  }
  .profil-himalkom-img img {
    width: 120px;
  }
  .inner-container {
    img {
      width: 190px;
    }
    h1 {
      height: 60px;
    }
    h3 {
      font-size: 12px;
    }
  }
  /* --------------------------- */
}