.bph-container {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 737px;
    background-image: url("./../../../../Assets/Divisi/BPH/bph-teks.png"), url("./../../../../Assets/Divisi/BPH/BPH_background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    border-top: 5px solid #000000;
    border-bottom: 5px solid #000000;
    margin-top: 13px
}

.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 50%;
}


.info-bph {
    flex: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info-bph h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    margin: 0 0 29px 0;

    text-shadow: 7px 4px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
}



.info-bph p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    width: 75%;
}

.staff-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0 0 0;
}

.staff-header h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    padding-bottom: 14px;

    text-shadow: 7px 4px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    padding: 0 100px 20px 100px;
    border-bottom: 8px solid #000000;
}

.staff {
    background-repeat: no-repeat;
    background-image: url('./../../../../Assets/Background/pattern-top.svg'), url('./../../../../Assets/Background/pattern-bottom.svg');
    background-position: top left, bottom right;
    background-size: contain;
}

.BPH-anggota {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: center;
    gap: 80px 60px;
    padding: 76px 116px 0px 106px;
}

.anggota-BPH {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.anggota-BPH img {
    height: 448.37px;
}

.program-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 160px 0 40px 0;
}

.program-header h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    text-shadow: 7px 4px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    padding: 0 16px 20px 16px;
    border-bottom: 8px solid #000000;
}

.program-header img {
    width: 572px;
}

@media(max-width: 1280px) {
    .bph-container {
        height: 100vh;
    }

    .info-bph h2 {
        font-size: 42px;
        text-shadow: 4px 3px 0px #040404;
        line-height: 49px;
    }


}

@media(max-width: 768px) {
    .bph-container {
        background-image: url("../../../../Assets/Divisi/BPH/bph-teks-tab.png"), url("../../../../Assets/Divisi/BPH/bph-background-tab.svg");
        height: 549px;
    }

    .logo-container {
        flex: 70% 1;
    }

    .info-bph h2 {
        font-size: 45px;
        line-height: 52px;
        letter-spacing: -2px;
    }

    .info-bph p {
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        width: 90%;
    }

    .staff-header h2 {
        font-size: 45px;
        padding: 0 50px 4px 50px;
        border-bottom: 6px solid #000000;
    }

    .BPH-anggota {
        padding: 44.23px 42px 0px 42px;
        gap: 40px;
        overflow-x: hidden;
    }

    .anggota-BPH img {
        height: 240.13px;
    }

    .BPH-program .program-header {
        padding: 87px 0 40px 0;
    }

    .program-header h2 {
        font-size: 45px;
        border-bottom: 6px solid #000000;
        padding: 0 16px 10px 16px;
    }
}

@media screen and (max-width: 480px) {
    .bph-container {
        background-image: url("../../../../Assets/Divisi/BPH/bph-teks-phone.png"), url("../../../../Assets/Divisi/BPH/bph_background-phone.svg");
        height: 522px;
    }

    .logo-container {
        display: none;
    }

    .info-bph {
        flex: 100% 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info-bph p {
        text-align: center;
        font-size: clamp(0.875rem, 0.625rem + 1.25vw, 1rem);
        line-height: 22px;
    }

    .info-bph h2 {
        font-size: 32px;
        line-height: 40px;
        margin: 240px 0 9px 0;
        letter-spacing: -2px;
        text-align: center;
    }

    .BPH-anggota {
        gap: 40px 10px;
        padding: 44.23px clamp(0.75rem, -3rem + 18.75vw, 2.625rem);
        padding-bottom: 0;
    }

    .anggota-BPH img {
        max-height: 235.88px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .BPH-program .program-header {
        padding: 40px 0 40px 0;
    }

    .program-header h2 {
        border-bottom: 3px solid #000000;
        text-shadow: 3px 2px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
        line-height: 28px;
        font-size: 22px;
        padding: 0 4px 0px 4px;
    }

    .program-header img {
        display: none;
    }

    .staff-header h2 {
        text-shadow: 3px 2px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
        line-height: 28px;
        font-size: 22px;
        padding: 0 10px 0px 10px;
        border-bottom: 3px solid #000000;
    }

    .staff-header img {
        display: none;
    }
}