.Komnews-Home-Content-Card {
    display: flex;
    justify-content: center;
    background-image: url("../../Assets/Background/Komnews-Home-Card.svg");
    background-size: cover;
    background-color: #EFC166;
    background-repeat: no-repeat;
    text-decoration: none;
    color: #000000;
    border: 2px solid #111827;
    
    padding: 17px;
    width: 413px;
    height: 376px;

    position: relative;
    cursor: pointer;
}

.newscard_img {
    object-fit: cover;
    border: 2px solid #040404;
    box-shadow: 3px 3px 0px #040404;
    width: 373px;
    max-height: 205px;
}

.Komnews-Home-Content h1 {
    font-family: Changa One;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-shadow: 1px 1px 0px #040404;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #040404;
    color: #f0ebe4;
    padding-top: 0.94rem;
}

.Komnews-Home-Content {
    display: flex;
    flex-direction: column;
}

.card_date {
    margin-top: auto;
    display: flex;
    border: #000000 solid 2px;
    width: 75px;
    justify-content: center;
    padding: 5px;
    background: #F0E4D4;
    box-shadow: 1.5px 2px 0px #000000;
}

.card_date p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
}

@media screen and (max-width: 990px) {
    .Komnews-Home-Content-Card {
        width: 350px;
        height: 300px;
        padding: 13px;
    }

    .newscard_img {
        width: 300px;
        height: 150px;
    }

    .Komnews-Home-Content h1 {
        font-size: 15px;
        line-height: 18px;
    }
}

@media screen and (max-width: 550px) {
    .Komnews-Home-Content-Card {
        width: 300px;
        height: 270px;
        padding: 11px;
    }

    .newscard_img {
        width: 250px;
        height: 130px;
    }

    .Komnews-Home-Content h1 {
        font-size: 14px;
        line-height: 15px;
        text-shadow: 0.7px 0.7px 0px #040404;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #040404;
    }
}