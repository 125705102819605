
.hero_news {
    display: flex;
    flex-direction: column;
    justify-content: center ;
    align-items: center;
    margin-top: 20px;
    height: 300px;
    background-image: url("./../../Assets/Komnews/bg-hero.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-bottom: solid 4px;
    border-top: solid 4px ;
}

.motion_news {
    text-align: center;
}

.motion_news > h1 {
    font-family: 'Sports World';
    font-style: normal;
    letter-spacing: -2px;
    font-weight: 400;
    font-size: 35px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
}

.motion_news > p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: lighter;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #111827;
    margin-top: 10px;
}

.title-head {
    justify-content: flex-start;
    margin: 80px 0px 30px 0px;
}

.title-head > h1 {
    font-family: 'Sports World';
    font-style: normal;
    letter-spacing: -2px;
    font-weight: 400;
    font-size: 35px;
    text-shadow: 3px 4px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
}

.topics {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding: 50px 100px;
    height: 1300px;
}

.topics-list{
    display: flex;
    flex-direction: column;
    padding-right: 25px;
    flex-wrap: nowrap;
    overflow: auto;
}


.topics-rec {
    background-color: rgba(179, 179, 179, 0.12);
    padding: 25px 20px;
    width: 432px;
    height: auto;
}

.topics-rec > h1 {
    font-family: 'Changa One';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.rec-list {
    display: flex;
    flex-wrap: wrap;
}

.recommend{
    display: flex;
    border: #000000 solid 2px;
    width:auto;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background:#E49800;
    box-shadow: 3px 4px 0px #000000;
    margin: 5px;
}

.recommend:active {
    transform: translateY(2px);
    transform: translateX(3px);
    box-shadow: 1px 1px 0px #000000;
}

.recommend > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.komnews-bold{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 8px;
    text-align: center;
    color: #111827;
    margin-top: 7px;
}

.topics-list > div {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.topics-list > div.fade-out {
    opacity: 0;
}

@media (max-width: 1330px) {
    .topics{
        flex-direction: column-reverse;
        padding: 50px 43px;
        justify-content:flex-end
    }
    .topics-rec{
        background-color: white;
        padding: 25px 0px;
        width:auto;
        height: auto;
    }
    .topics-rec > h1 {
        font-family: 'Changa One';
        font-style: italic;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
    }
    .rec-list{
        margin: 15px 0px;
    }
    .topics-list{
        padding-right: 0px;
    }
}

@media (max-width: 640px) {
    .topics{
        padding: 0px 15px 50px 15px;
        height: 950px;
    }
    .topics-rec{
        background-color: white;
        padding: 25px 0px;
        width:auto;
        height: auto;
    }
    .topics-rec > h1 {
        font-family: 'Changa One';
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        line-height: 18px;
    }
    .rec-list{
        margin: 10px 0px;
    }
}

@media (max-width:480px) {
    .motion_news > p {
        max-width: 400px;
    }
    .recommend{
        padding: 4px 8px;
        box-shadow: 2.5px 3px 0px #000000;
        margin: 4px;
    }
    .recommend:active {
        transform: translateY(2px);
        transform: translateX(3px);
        box-shadow: 1px 1px 0px #000000;
    }
    .recommend > span {
        font-size: 11px;
        line-height: 15px;
    }
}