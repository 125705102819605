.Headline {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.headline {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-image: url("./../../Assets/Komnews/Headline/bg-headline.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 1280px;
    height: 463px;
    margin-bottom: 100px;
    padding: 50px 70px;
    border: solid 4px #000000;
    gap: 40px;
}

.desc-news {
    display: flex;
    flex-direction: column;
}

.desc-news > h1 {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
}

.desc-news > p {
    width: 670px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 15px 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.button_headline {
    box-sizing: border-box;
    font-family: 'Montserrat';
    width: 156px;
    height: 36px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 3px 4px 0px #000000;
    color: white;
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}

.button_headline:active {
    transform: translateY(2px);
    transform: translateX(3px);
    box-shadow: 1px 1px 0px #000000;
}

.headline_img{
    object-fit: cover;
    border: 2px solid #040404;
    box-shadow: 3px 3px 0px #040404;
    width: 344px;
    height: 344px;
}

@media (max-width:1280px) {
    .headline{
        width: 688px;
        gap: 25px;
    }
    .desc-news > h1{
        max-width: 324px;
        font-size: 24px;
    }
    .desc-news > p{
        max-width: 324px;
    }
    .headline_img{
        width: 270px;
        height: 270px;
    }
}

@media (max-width:690px) {
    .headline{
        gap: 25px;
        display: flex;
        flex-direction: column-reverse;
        width: 380px;
        height: auto;
        margin-bottom: 40px;
    }
    .desc-news{
        align-items: center;
    }
    .desc-news > h1{
        text-align: center;
        line-height: 25px;
    }
    .desc-news > p{
        font-size: 16px;
        text-align: center;
    }
}

@media (max-width:480px) {
    .headline{
        width: 340px;
    }
    .desc-news > h1{
        max-width: 300px;
        font-size: 23px;
    }
    .desc-news > p{
        max-width: 300px;
        font-size: 15px;
    }
    .headline_img{
        width: 250px;
        height: 250px;
    }
}