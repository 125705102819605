.preloader {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background: #E49800; /* Replace $black with the actual color value */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF; /* Replace $white with the actual color value */
    overflow: hidden !important;
}

.preloader .texts-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    font-size: 20px;
    overflow: hidden;
    font-weight: 800;
    opacity: 0;
    color: #FFFFFF; /* Replace $white with the actual color value */
    gap: 10px;
}

/* Media Query for Phones */
@media (max-width: 767px) {
    .preloader .texts-container {
        font-size: 13px;
        gap: 7px;
    }
}
