.Dokumentasi-container {
    margin: 80px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
}

.Dokumentasi-carousel{
    display: flex;
    background-color: black;
    justify-content: center;
    padding: 54px 0;
    gap: 20px;
}

.Dokumentasi-content {
    max-width: 1057px;
    width: 90%;
    height: 704px;
    background: #453755;
    border: 3px solid #1F2937;
    box-shadow: 7px 7px 0px #1F2937;
}

.Dokumentasi-content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.Dokumentasi-head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px 0 48px 0;
    width: 100%;
}

.image-preview {
    background-position: center;
    background-size: cover;
    width: 90%;
    max-width: 696px;
    height: 342px;
    border: 3px solid #16052B;
    box-shadow: 9px 10px 0px #16052B;
}
  

.arrow-left {
    padding-right: clamp(1.25rem, -3.63896rem + 7.639vw, 4rem);
    cursor: pointer;
}

.arrow-right {
    padding-left: clamp(1.25rem, -3.63896rem + 7.639vw, 4rem);
    cursor: pointer;
}

.IWDC-main-pic {
    width: 696px;
    height: 342px;
    border: 3px solid #16052B;
    box-shadow: 9px 10px 0px #16052B;
}

.Dokumentasi-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 231px;
    height: 52px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 4px 4px 0px #000000;
    transform: translateY(25px);
}

.Dokumentasi-header h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.Dokumentasi-carousel {
    overflow-x: auto;
}

.Dokumentasi-carousel img{
    width: 215px;
    height: 136px;
}

@media(max-width: 780px) {
    #image-slider  .Dokumentasi-content {
        width: 90%;
        max-width: 668px;
        height: 704px;
        border: 3px solid #1F2937;
        box-shadow: 7px 7px 0px #1F2937;
        margin-left:auto ;
        margin-right:auto ;
    }
    #image-slider  .Dokumentasi-carousel{
        padding-left:20px;
        padding-right: 20px;
    }

    #image-slider .arrow-left, #image-slider .arrow-right {
        padding-right:0px;
    }
    
    #image-slider .arrow-right {
        padding-left: 0px;
    }

    #image-slider .image-preview {
        margin: 0 7px;
    }

    #image-slider .arrow{
        width: 7%;
        height: auto;
    }


}

@media screen and (max-width: 480px) {
    #image-slider  .Dokumentasi-content {
        max-width: 400px;
        height: 763px;
        border: 3px solid #1F2937;
        box-shadow: 7px 7px 0px #1F2937;
    }

    .image-preview {
        max-width: 285px;
        height: 416px;
    }
    
    .Dokumentasi-carousel{
        padding: 45px;
        /* flex-wrap: nowrap; */
        overflow-x: auto;
    }

    #image-slider .arrow-left {
        padding-right:2px;
    }
    
    #image-slider .arrow-right {
        padding-left: 2px;
    }

    #image-slider .arrow{
        width: 10%;
    }

    .Dokumentasi-carousel > div {
        flex: 0 0 215px;
        margin: 0 10px;
    }
}



