.form-desc{
    padding: 70px;
    background-image: url("../../Assets/Background/descpage_bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-desc{
    display: flex;
    align-items: center;
}

.icon1{
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding: 4px 8px 4px 10px;
    width: 100px;
    height: 28px;
    background: #E49800;
    border: 1px solid #111827;
    box-shadow: 3px 4px 0px #000000;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
}

.icon2{
    color: var(--gray-07, #4B5563);
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.25px;
    padding: 4px 10px;
    display: flex;
    border: 1px solid var(--gray-10, #111827);
    background: var(--cream-06, #F0E4D4);
    width: auto;
    height: 24px;
    justify-content: center;
    align-items: center;
}

.title-desc{
    color: #010101;
    /* Changa One/Reguler/Display Medium */
    font-size: 45px;
    font-family: Changa One;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    margin: 18px 0 18px 0;
}

.date-desc{
    color: #A4A29F;
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px;
}

.text-desc{
    color: #000;
    text-align: justify;
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 40px 0 40px 0px;
}

.text-desc > p{
    margin-bottom: 15px;
}

.img-desc{
    display: flex;
    justify-content: center;
    margin: 40px 40px;
}

.img-desc img{
    width: 1280px;
    object-fit: cover;
}

.desc-footer > h1 {
    color: #000;
    text-align: justify;
    font-size: 32px;
    font-family: Changa One;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.desc-card{
    display: flex;
    gap: 1.5rem;
    padding: 2rem 0 2rem 0;
    overflow: auto;
    width: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.desc-card::-webkit-scrollbar {
    display: none;
}
@media (max-width: 1300px) {
    .img-desc img{
        width: 900px;
    }
}
@media (max-width: 808px) {
    .form-desc{
        padding: 40px;
    }
    .title-desc{
        font-size: 32px;
        line-height: 40px;
    }
    .img-desc img{
        width: 580px;
    }
}

@media (max-width: 590px) {
    .form-desc{
        padding: 20px;
    }
    .title-desc{
        font-size: 24px;
        line-height: 32px;
    }
    
    .text-desc{
        font-size: 14px;
        line-height: 20px;
        margin: 40px 0 30px 0;
    }
    .img-desc img{
        width: 398px;
    }
    .img-desc{
        margin: 20px 20px;
    }
    .date-desc{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 14px;
    }
    .icon1{
        font-size: 11px;
        padding: 4px 10px 4px 10px;
        width: auto;
        box-shadow: 2px 3px 0px #000000;
        margin-right: 18px;
        line-height: 16px;
        font-weight: 400;
    }
    
    .icon2{
        font-size: 11px;
        line-height: 16px;
        padding: 3px 8px;
    }
}

@media (max-width:420px) {
    .img-desc img{
        width: 340px;
    }
    .img-desc{
        margin: 15px 15px;
    }
}