.Footer-background {
    background-color: #F3E9DD;
}

.Footer {
    font-family: montserrat;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
}

.Footer-Logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding-top: 127px;
    /* margin: 0 343px 54px 354px; */
    width: 100%;
    overflow-x: hidden;
    padding-left:5% ;
    padding-right:5% ;
}

.Footer-Logos img{
    width: 100%;
    height: 100%;
}

.Footer-Logos .himalkom {
    max-width: 96px;
    max-height: 96px;
}

.Footer-Logos .ipb {
    max-width:  366.806px;
    max-height: 96px;
}

.Footer-Logos .connect {
    max-width: 160px;
    max-height: 99px;
}

.Footer-social {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 54px 0 47px 0;
}

.Footer-divider p {
    border: 1.5px dashed #9CA3AF;
    margin: 0 70px 24px 70px;
}


.Footer-address {
    display: flex;
    justify-content: space-between;
    margin: 0 62px 0 70px;
    font-size: 16px;
    line-height: 24px;

}

.Footer-copyright {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding-bottom: 25px;
    font-size: 16px;
    line-height: 24px;
}

@media(max-width: 768px) {
    .Footer-Logos {
        margin: 0 auto;
        padding-top: 40px;
        overflow-x: hidden;
        padding-left:5% ;
        padding-right:5% ;
    }



    .Footer-Logos .himalkom {
        max-width: 80px;
        max-height: 80px;
    }

    .Footer-Logos .ipb {
        max-width: 184px;
        max-height: 48px;
    }

    .Footer-Logos .connect {
        max-width: 144px;
        max-height: 87px;
    }

    .Footer-address {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .address {
        text-align: center;
    }

    .contact {
        padding-top: 40px;
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .Footer-Logos {
        flex-direction: column;
        margin: 0 auto;
        padding-top: 40px;
    }

    
    .Footer-copyright {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}