
.ilkommunity-bg{
    background-image: url(./../../Assets/Background/bgilkomunity.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 110vh;
}

.header .ilkommunity-header {
    margin-top: 100px;
    margin-bottom: 100px;

    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-shadow: 1.5px 2.7px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F0E4D4;
}

.ilkommunity {
    display: grid;
    margin: 0 81px 64px 81px;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 48px;
    justify-items: center;
}

.ilkommunity li {
    list-style: none;
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 29px;
}

.ilkommunity a{
    text-decoration: none;
}

.ilkommunity-agriux {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-agriux:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}

.ilkommunity-agriux img {
    margin-top: 35px;
    border: 2px solid;
    border-radius: 122px;
    width: 122px;
    height: 122px;
}

.ilkommunity-csi {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-csi:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}

.ilkommunity-csi img {
    margin-top: 35px;
    border-radius: 122px;
    border: 2px solid;
    width: 122px;
    height: 122px;
}

.ilkommunity-agribot {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-agribot:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}

.ilkommunity-agribot img {
    margin-top: 35px;
    border-radius: 122px;
    border: 2px solid;
    width: 122px;
    height: 122px;
}

.ilkommunity-cp {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-cp:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}

.ilkommunity-cp img {
    margin-top: 35px;
    border: 2px solid;
    border-radius: 122px;
    width: 122px;
    height: 122px;
}

.ilkommunity-daming {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-daming:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}

.ilkommunity-daming img {
    margin-top: 35px;
    border: 2px solid;
    border-radius: 122px;
    width: 122px;
    height: 122px;
}

.ilkommunity-iwdc {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-iwdc:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}
.ilkommunity-iwdc img {
    margin-top: 35px;
    width: 122px;
    border: 2px solid;
    border-radius: 122px;
    height: 122px;
}

.ilkommunity-gary {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-gary:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}
.ilkommunity-gary img {
    margin-top: 35px;
    border-radius: 122px;
    border: 2px solid;
    width: 122px;
    height: 122px;
}

.ilkommunity-mad {
    background-image: url(../../Assets/Background/Ilkommunity\ Card\ Desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    width: 304px;
    height: 253px;
    justify-items: center;
    transition: 0.3s;
}

.ilkommunity-mad:hover {
    box-shadow: 10px 10px 0px #000000;
    transform: translateY(-10px);
    transform: translateX(-10px);
}

.ilkommunity-mad img {
    border: 2px solid;
    border-radius: 122px;
    margin-top: 35px;
    width: 122px;
    height: 122px;
}

.header h3 {
    font-size: 32px;
    margin: 0 0 48px 0;
}