.Syntax-card {
    max-width: 389px;
    text-align: center;
}


.Syntax-card img {
    width: 100%;
    max-height: 493px;
    height: 100%;
    object-fit: cover;
    border: 2.5px solid black;
    cursor: pointer;
}

.Syntax-card h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    width: 100%;
    padding: 20px 0 5px 0;
}

.Syntax-card p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 7px;
}


@media (max-width: 768px) {
    .Syntax-card {
        display: flex;
        flex-direction: column;
        max-width: 211px;
        width: 100%;
    }

    .Syntax-card img {
        border: 2px solid black;
        box-shadow: none;
        object-fit: cover;
    }

    .Syntax-card h3 {
        font-size: 15px;
        line-height: 15px;
    }

    .Syntax-card p {
        font-size: 11px;
        line-height: 9.769px;
        padding-bottom: 8px;
        font-weight: 400;

    }
}

@media (max-width: 480px) {
    .Syntax-card {
        max-width: 190px;
    }
}
