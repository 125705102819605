.topic{
    display: flex;
    border-left: #000000 solid 2px;
    height: 242px;
    width: 758px;
    margin-bottom: 20px;
    flex-direction: row;
    padding: 10px 30px;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #000000;
    cursor: pointer;
}

.topic:hover {
    background: #E49800;
}

.topic-desc > h1 {
    font-family: 'Changa One';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    max-width: 500px;
}

.topic-desc > p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    max-width: 500px;
    margin: 10px 0px;
    overflow-wrap: break-word;
    max-height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}


.topic-category {
    display: flex;
    border: #000000 solid 2px;
    width: 150px;
    justify-content: center;
    padding: 5px;
    background: #F0E4D4;
    box-shadow: 3px 4px 0px #000000;
}

.topic-category > p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.news_img {
    object-fit: cover;
    width: 140px;
    height: 140px;
}

@media (max-width:1330px) {
    .topic{
        width: auto;
        padding: 10px 18px;
    }
    .news_img {
        width: 170px;
        height: 170px;
    }
    .topic-desc > h1 {
        font-size: 26px;
        max-width: 840px;
    }
    .topic-desc > p {
        font-size: 16px;
        max-width: 840px;
        line-height: 23px;
    }
    
}

@media (max-width:900px) {
    .topic{
        width: auto;
        height: 219px;
    }
    .news_img{
        width: 118px;
        height: 118px;
    }
    .topic-desc > h1 {
        font-size: 20px;
        max-width: 470px;
        line-height: 18px;
    }
    .topic-desc > p {
        font-size: 11px;
        max-width: 470px;
        line-height: 14px;
    }
}

@media (max-width:640px) {
    .topic{
        height: auto;
    }
    .news_img{
        width: 94px;
        height: 94px;
    }
    .topic-desc > h1 {
        font-size: 14px;
        max-width: 370px;
        line-height: 16px;
    }
    .topic-desc > p {
        font-size: 8px;
        max-width: 370px;
        line-height: 12px;
    }
    .topic-category{
        border: #000000 solid 1px;
        width: 115px;
        justify-content: center;
        padding: 3.5px;
        background: #F0E4D4;
        box-shadow: 2px 3px 0px #000000;
    }
    .topic-category > p{
        font-size: 8px;
        line-height: 10px;
    }
}

@media (max-width:480px) {
    .news_img{
        width: 90px;
        height: 75px;
        margin-left: 5px;
    }
    .topic-desc > h1 {
        font-size: 12px;
        max-width: 220px;
        line-height: 14px;
    }
    .topic-desc > p {
        font-size: 7px;
        max-width: 220px;
        line-height: 9px;
    }
    .topic-category{
        padding: 3px;
    }
    .topic-category > p{
        font-size: 7px;
        line-height: 8px;
    }
    
}
