#IG-header-container {
    background-image: url('../../Assets/Background/IGallery_bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    height: 100vh;
    margin-top: .8rem;
    display: flex;
    overflow-x: hidden;
}

.IG-header-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    transform: translate(242px,-1.5rem);
    margin-left: 3%;
}

.IG-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 490px;
    height: auto;
}

.IG-header h2 {
    font-family: 'Sports World';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
}

.IG-header p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.IG-main{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 80px 10px 80px 10px;
    height: auto;

    background-image: url('./../../Assets/Background/pattern-top.svg'), url('./../../Assets/Background/pattern-bottom.svg');
    background-position: top right, bottom left;
    background-size: contain;
    background-repeat: no-repeat;
}
.IG-h1{
    font-family: 'Sports World';
    font-style: normal;
    font-size: 45px;
    font-weight: 400;
    line-height: 52px;
    text-shadow: 4px 5px 0px #040404;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #040404;
    color: #F0E4D4;
    margin-bottom: 24px;
    text-align: center;
}

.IG-main-button{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.IG-list{
    display: flex;
    border: #000000 solid 2px;
    width:auto;
    justify-content: center;
    align-items: center;
    padding: 9px;
    background:#E49800;
    box-shadow: 2.5px 2.5px 0px #000000;
    margin: 5px;
}

.IG-list:active {
    transform: translateY(2px);
    transform: translateX(3px);
    box-shadow: 1px 1px 0px #000000;
}

.IG-list > span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.IG-main-content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
}

@media(max-width: 1200px){
    #IG-header-container{
        background-image: url('../../Assets/Background/IGallery_tab.svg');
        background-position: center;
        height: 640px;
    }

    .IG-header-wrapper {
        grid-template-columns: none;
        transform: translateY(0rem);
        margin-left: 5%;
    }

    .IG-header{
        width: 325px;
    }
    
    .IG-header p{
        width: 100%;
        font-size: 16px;
    }

    .IG-header h2{
        font-size: 45px;
        line-height: 52px;
    }
}

@media(max-width: 640px) {
    #IG-header-container{
        background-image: url('../../Assets/Background/IGallery_Phone.svg');
        
    }
    .IG-header-wrapper{
        display: flex;
        flex-direction: column;
        margin-left:auto;
        margin-right:auto;
        transform: translateY(8rem);
    }
    .IG-header h2{
        font-size: 46px;
        line-height: 44px;
    }
    .IG-header p{
        font-size: 14px;
        line-height: 20px;
    }
    .IG-h1{
        font-size: 36px;
        line-height: 44px;
        text-shadow: 3px 4px 0px #040404;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #040404;
    }
    .IG-main-content{
        gap: 0px;
    }
}

